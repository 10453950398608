import { avatarShapeClass, pillClass, textClass } from './index.css';

interface SkeletonBaseProps {
  height?: number | string;
  width?: number | string;
  children?: React.ReactNode;
  className?: string;
}

interface TextSkeletonProps extends SkeletonBaseProps {
  lines?: number;
  lineClassName?: string;
}

interface AvatarSkeletonProps extends SkeletonBaseProps {
  variant: keyof typeof avatarShapeClass;
}

export function AvatarSkeleton({
  variant,
  children,
  height,
  width,
  className,
}: AvatarSkeletonProps) {
  return (
    <div className={`${avatarShapeClass[variant]} ${className || ''}`} style={{ height, width }}>
      {children}
    </div>
  );
}

export function TextSkeleton({
  children,
  height,
  lines = 1,
  width,
  className,
  lineClassName,
}: TextSkeletonProps) {
  return (
    <div style={{ width }} className={className}>
      {Array.from({ length: lines }).map((_, index) => (
        <div key={index} style={{ height }} className={`${textClass} ${lineClassName || ''}`}>
          {children}
        </div>
      ))}
    </div>
  );
}

export function PillSkeleton({ children, height, width, className }: SkeletonBaseProps) {
  return (
    <div style={{ width, height }} className={`${pillClass} ${className || ''}`}>
      {children}
    </div>
  );
}
