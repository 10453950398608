import * as RadixSelect from '@radix-ui/react-select';
import { ReactNode, forwardRef, useState } from 'react';

import { popoverItemClass, popoverItemsClass } from '@/styles/popover.css';

import { CheckmarkIcon, ChevronDownSmallIcon } from '@/ui/Icons';
import { popoverClass } from '@/ui/Multiselect/index.css';

import { classNames } from '@/utils/classnames';

import {
  contentClass,
  highlightedTextClass,
  iconClass,
  indicatorClass,
  scollUpIconClass,
  scrollButtonClass,
  triggerVariants,
} from './index.css';

type Item = {
  /**
   * The label to be displayed
   */
  label: ReactNode;
  /**
   * The underlying value of the item
   */
  value: string;
};

type SelectProps = {
  variant: keyof typeof triggerVariants;
  items: Item[];
  placeholderText: string;
  /**
   * The item that should be selected by default.
   * This value will be used to initialize the component.
   * The component will then manage its own state.
   */
  defaultSelected?: string;
  /**
   * The item that should be selected.
   * This value will be used instead of the component's internal state.
   * Use this prop if you want to control the state of the component.
   */
  selected?: string;
  /**
   * Callback that is called when the selected items change
   *
   * @param selected - The currently selected item represented by its value
   */
  onSelect?: (selected: string) => void;
  disabled?: boolean;

  /**
   * Whether the popover should be displayed or not
   * If this parameter is set, the open state of the component becomes controlled.
   */
  open?: boolean;

  /**
   * Sets the default open state of the component.
   * The value of this component will be used to initialize the component but the component
   * will stay uncontrolled.
   */
  defaultOpen?: boolean;

  /**
   * Callback function that will be called when the open state of the component changes
   */
  onOpenChange?: (newState: boolean) => void;
  className?: string;
};
export const Select = forwardRef<HTMLButtonElement, SelectProps>(function Select(
  {
    variant = 'primary',
    items,
    placeholderText,
    defaultSelected,
    selected,
    onSelect,
    open,
    defaultOpen,
    onOpenChange,
    disabled = false,
    className,
  },
  ref,
) {
  const [internalSelected, setInternalSelect] = useState(defaultSelected);
  const [isOpen, setIsOpen] = useState(defaultOpen === undefined ? false : defaultOpen);

  const valueChangeHandler: (value: string) => void = (value) => {
    if (onSelect) {
      onSelect(value);
    }
    if (selected === undefined) {
      setInternalSelect(value);
    }
  };

  const openChangeHandler: (state: boolean) => void = (state) => {
    if (onOpenChange) {
      onOpenChange(state);
    }
    if (open === undefined) {
      setIsOpen(state);
    }
  };

  return (
    <RadixSelect.Root
      open={open === undefined ? isOpen : open}
      onOpenChange={openChangeHandler}
      value={selected === undefined ? internalSelected : selected}
      onValueChange={valueChangeHandler}
    >
      {/** we need to override the pointerDown handler because of a bug with radix-ui
https://github.com/radix-ui/primitives/issues/1641
        */}
      <RadixSelect.Trigger
        onPointerDown={(e) => e.preventDefault()}
        onClick={() => setIsOpen(!open)}
        disabled={disabled}
        ref={ref}
        className={classNames(triggerVariants[variant], className)}
      >
        <RadixSelect.Value placeholder={placeholderText} />
        <RadixSelect.Icon>
          <ChevronDownSmallIcon className={iconClass} />
        </RadixSelect.Icon>
      </RadixSelect.Trigger>
      <RadixSelect.Portal>
        <RadixSelect.Content
          className={`${popoverClass} ${contentClass}`}
          align="end"
          position="popper"
        >
          <RadixSelect.ScrollUpButton className={scrollButtonClass}>
            <ChevronDownSmallIcon className={scollUpIconClass} />
          </RadixSelect.ScrollUpButton>
          <RadixSelect.Viewport className={popoverItemsClass}>
            {items.map(({ label, value }) => {
              return (
                <RadixSelect.Item
                  key={value}
                  value={value}
                  className={`${popoverItemClass} ${highlightedTextClass}`}
                >
                  <RadixSelect.ItemText>{label}</RadixSelect.ItemText>
                  <RadixSelect.ItemIndicator className={indicatorClass}>
                    <CheckmarkIcon />
                  </RadixSelect.ItemIndicator>
                </RadixSelect.Item>
              );
            })}
          </RadixSelect.Viewport>
          <RadixSelect.ScrollDownButton className={scrollButtonClass}>
            <ChevronDownSmallIcon />
          </RadixSelect.ScrollDownButton>
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </RadixSelect.Root>
  );
});
